@import "styles/vars";
@import "styles/mixins";
@import "styles/animations";

.pay_table {
  @include flex-justify-align(space-between, center);
  padding: 13px;
  border-top: 1px solid rgba(221, 221, 221, 1);
  border-bottom: 1px solid rgba(221, 221, 221, 1);
  margin-bottom: 14px;

  &_container {
    display: flex;
    flex-direction: column;
    gap: 6px;

    &_title {
      @include font-size(8, $medium, 10);
      color: $font-color-gray-third;
    }

    &_value {
      @include font-size(14, $regular, 14);
      letter-spacing: 0.03em;
      opacity: 0.65;
    }
  }
}

.unpaid_table {
  @include flex-justify-align(space-between);
  padding: 13px;
  margin-bottom: 14px;
  border-top: 1px solid rgba(221, 221, 221, 1);
  border-bottom: 1px solid rgba(221, 221, 221, 1);

  &_balance {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &_title {
      @include font-size(8, $medium, 10);
      letter-spacing: 0.08em;
    }

    &_value {
      @include font-size(14, $medium, 14);
      color: rgba(248, 0, 49, 1);
    }
  }

  &_btn {
    @include purple-btn();
    @include font-size(14, $bold, 20);
    width: 106px;
    height: 34px;
  }
}

.schedule_table {
  padding: 13px;
  margin-bottom: 14px;
  border-top: 1px solid rgba(221, 221, 221, 1);
  border-bottom: 1px solid rgba(221, 221, 221, 1);

  &_title {
    @include font-size(14, $medium, 20);
    color: $font-color-black-third;
    padding-bottom: 20px;
    margin-left: -6px;
  }

  &_list {
    display: flex;
    flex-direction: column;

    .schedule_item {
      position: relative;
      @include flex-justify-align(space-between);
      border-left: 1.5px solid rgba($font-color-black-secondary, 0.5);
      padding-left: 17px;
      padding-bottom: 20px;

      &:last-child {
        border: none;

        .schedule_item_check,
        .schedule_item_uncheck {
          left: -6px;
        }
      }

      &_check {
        position: absolute;
        top: 0;
        left: -7px;
        width: 13px;
        display: flex;
        height: 13px;

        svg {
          border-radius: 50%;
          width: 13px;
          height: 13px;
          background-color: rgba(0, 0, 0, 1);

          path:nth-child(1) {
            fill: rgba(0, 0, 0, 1);
          }

          path:nth-child(2) {
            fill: $white;
            display: block;
          }
        }
      }

      &_uncheck {
        @extend .schedule_item_check;

        svg {
          background-color: $white;
          border: 1px solid rgba($font-color-black-secondary, 0.5);

          path:nth-child(1) {
            fill: rgba($font-color-black-secondary, 0.5);
          }

          path:nth-child(2) {
            display: none;
          }
        }
      }

      &_info {
        display: flex;
        flex-direction: column;
        gap: 2px;

        &_date {
          @include font-size(12, $regular, 14);
          color: rgba($font-color-black-secondary, 0.65);
        }

        &_amount {
          @include font-size(14, $medium, 16, -0.5);
          color: rgba(101, 101, 101, 1);
        }

        &_instalment {
          @include font-size(14, $thin, 16, -0.5);
          color: rgba(101, 101, 101, 1);
        }
      }

      &_btn {
        @include purple-btn();
        height: 34px;
        width: 124px;

        @include font-size(14, $medium, 20);

        &:disabled {
          background: $background-button;
          opacity: 0.2;
          color: $white;
          border-radius: 4px;
        }
      }

      .paid_schedule {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 6px;

        &_title {
          @include font-size(8, $medium, 10);
          letter-spacing: 0.08em;
          color: $font-color-gray-third;
        }

        &_value {
          @include font-size(14, $regular, 14);
          letter-spacing: 0.03em;
          color: rgba($font-color-black-secondary, 0.65);
        }
      }
    }
  }

  .divider {
    @include flex-justify-align(space-between);
    padding: 15px 0;
    margin-right: -13px;

    p {
      &:first-child {
        padding-right: 16px;

        @include font-size(12, $bold, 14);
        color: rgba($font-color-black, 0.65);
      }

      &:last-child {
        height: 1px;
        width: 100%;
        background-color: rgba(221, 221, 221, 1);
      }
    }
  }

  .total_pay {
    @include flex-justify-align(space-between);

    &_balance {
      display: flex;
      flex-direction: column;
      gap: 6px;

      &_title {
        @include font-size(8, $medium, 10);
        letter-spacing: 0.08em;

        color: $font-color-gray-third;
      }

      &_value {
        @include font-size(14, $medium, 14);
        letter-spacing: 0.03em;

        color: rgba(248, 0, 49, 1);
      }
    }

    &_btn {
      @include purple-btn();
      height: 34px;
      width: 124px;

      @include font-size(14, $medium, 20);

      &:disabled {
        background: $background-button;
        opacity: 0.2;
        color: $white;
        border-radius: 4px;
      }
    }
  }

  @media (max-width: 450px) {
    margin-bottom: 30px;

    &_list {
      .schedule_item {
        &_btn {
          width: 116px;
        }
      }
    }

    .divider {
      margin-left: -7px;
    }

    .total_pay {
      margin-left: -7px;

      &_btn {
        width: 116px;
      }
    }
  }
}
